// Externals
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiGetPolicyDocumentList } from '../async/documents';

// Get policy documentlist
export const getPolicyDocumentList = createAsyncThunk('policy/GET_POLICY_DOCUMENTLIST', 
	async (args, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return apiGetPolicyDocumentList(args, customerLoginId, token, rejectWithValue);
	}
);

// Clear the policy documents on logout
export const clearPolicyDocuments = createAction('policy/CLEAR_POLICY_DOCUMENTS');
