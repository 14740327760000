export const addressMatchesSelector = state => state.address.addressMatches.filter(x => !x.match(/^Too many/));

export const postalAddressMatchesSelector = state => state.address.postalAddressMatches.filter(x => !x.match(/^Too many/));

export const residentialPostcodeValidSelector = state => {
	const address = { ...state.contact.pendingUpdate.ResidentialAddress }
	const testPostcode = (address.PostCode + "").replace(/\s/, '');
	const testState = address.State;
	let result = undefined;
	if(state.address.postcodeMatches && state.address.postcodeMatches.length > 0){
		result = state.address.postcodeMatches[0][testPostcode] === testState;
	}
	return result ?? false;
};

export const postalAddressPostcodeValidSelector = state => {
	const address = { ...state.contact.pendingUpdate.PostalAddress }
	const testPostcode = (address.PostCode + "").replace(/\s/, '');
	const testState = address.State;
	let result = undefined;
	if(state.address.postalPostcodeMatches && state.address.postalPostcodeMatches.length > 0){
		result = state.address.postalPostcodeMatches[0][testPostcode] === testState && testState;
	}
	return result ?? false;
};