// Externals
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Selectors
import { policySummariesSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';

// Components
import OverdueBanner from '../components/Widgets/OverdueBanner';
import Container from '../components/Layout/Container';
import PolicySummary from '../components/Policy/Summary';

// Styles
import './MyPolicies.scss';
import { useEffect } from 'react';

// Dashboard
const MyPolicies = () => {
	const navigate = useNavigate();
	const policies = useSelector(policySummariesSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myInsurancePolicies'));
	const [ params ] = useSearchParams();
	useEffect(() => {
		if(params.has("dest")){		
			if(params.has("policy")){				
				switch(params.get("dest")){
					case "updatepayment":
						navigate(`/update/payment/${params.get("policy")}`);
						break;
					case "payment":
						navigate(`/payment/${params.get("policy")}`);
						break;
					case "correspondence":
						navigate(`/documents/${params.get("policy")}`);
						break;
					
				}
			}
		}
	}, [params]);
	return (<>

		<OverdueBanner />

		<Container>

			<div className='page page-dashboard'>

				<h1>{pageHeading}</h1>
				<div className='policy-summaries'>
					{policies.map((policy, index) => (
						<PolicySummary key={index} {...policy} />
					))}
				</div>
			</div>

		</Container>
	</>);
};

export default MyPolicies;
