// Externals
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Selectors
import { contactUpdateInProgress, 
	editContactSelector, 
	updateContactErrorsSelector,
	addressLookupSelector,
	postalAddressLookupSelector } from '../../store/selectors/contact';

import { residentialPostcodeValidSelector,
	postalAddressPostcodeValidSelector } from '../../store/selectors/address';

// Actions
import { updateContactField,
	updateCustomerContacts,
	updateContactCopyResidentialToPostal,
	resetPendingUpdate, 
	resetPendingResidentialUpdate,
	resetPendingPostalUpdate} from '../../store/actions/contact';

import { getPostcodeMatches, resetPostCodeMatches } from '../../store/actions/address';

// Form elements
import AddressLookup from './AddressLookup';

// Components
import FlashMessage from '../Widgets/FlashMessage';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';

// Constants
import { statesCA as states } from '../../store/constants';
import config from '../../config';

// Styles
import './ContactDetails.scss';

// Utility
import { preventNonNumericInput } from '../../util/validate';
import { contactUpdateFailureMessageSelector, editContactDetailsTermsSelector } from '../../store/selectors/brand';

// Helpers
const markValid = (element) => {
	if (element) {
		element.classList.remove('invalid');
		element.classList.add('valid');
	}
};

const markInvalid = (element) => {
	if (element) {
		element.classList.add('invalid');
		element.classList.remove('valid');
	}
};

const markAddressFieldInvalid = (formRef, individualFieldClass, searchFieldClass, addressMode=-1) => {
	if (addressMode === true || addressMode === -1) {
		markInvalid(formRef.current.querySelector(individualFieldClass));
	} else {
		markInvalid(formRef.current.querySelector(searchFieldClass));
	}
}

const validateResidentialAddress = (formRef, contact, manualAddressMode) => {
	let isValid = true;
	// Residential AddressLine
	if (contact.ResidentialAddress.AddressLine.length < 5) {
		markAddressFieldInvalid(formRef, '.field-contact-address', '.field-contact-address-lookup', manualAddressMode)		
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-address'));
	}

	// Residential Suburb
	if (contact.ResidentialAddress.Suburb.length < 3) {
		markAddressFieldInvalid(formRef, '.field-contact-suburb', '.field-contact-address-lookup', manualAddressMode)
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-suburb'));
	}

	// Residential Postcode
	if (!/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(contact.ResidentialAddress.PostCode)) {
		markAddressFieldInvalid(formRef, '.field-contact-postcode', '.field-contact-address-lookup');
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postcode'));
	}

	return isValid;
}

const validatePostalAddress = (formRef, contact, manualPostalAddressMode) => {
	let isValid = true;
	// Postal AddressLine
	if (contact.PostalAddress.AddressLine.length < 5) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-address', '.field-contact-postal-address-lookup', manualPostalAddressMode);
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-address'));
	}

	// Postal Suburb
	if (contact.PostalAddress.Suburb.length < 3) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-suburb', '.field-contact-postal-address-lookup', manualPostalAddressMode);
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-suburb'));
	}

	// Postal Postcode
	if (!/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(contact.PostalAddress.PostCode)) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-postcode', '.field-contact-postal-address-lookup');
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-postcode'));
	}

	return isValid;
}

const defaultCountryId = config.DEFAULT_COUNTRY_ID;

// Contact Details Form
const ContactDetails = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formRef = useRef();
	const validationActive = useRef(false);
	const contact = useSelector(editContactSelector);
	const editContactDetailsTermsHtml = useSelector(editContactDetailsTermsSelector);
	const updateContactFailureMessage = useSelector(contactUpdateFailureMessageSelector);
	const showError = useSelector(updateContactErrorsSelector);
	const spinner = useSelector(contactUpdateInProgress);
	const residentialAddress = useSelector(addressLookupSelector);
	const postalAddress = useSelector(postalAddressLookupSelector);

	const residentialAddressPostcodeValid = useSelector(residentialPostcodeValidSelector);
	const postalAddressPostcodeValid = useSelector(postalAddressPostcodeValidSelector);

	const [ manualAddressMode, setManualAddressMode ] = useState(false);
	const [ manualPostalAddressMode, setManualPostalAddressMode ] = useState(false);
	const [ hasSearchedPostCode, setHasSearchedPostCode] = useState(false);
	const [ hasSearchedPostalPostCode, setHasSearchedPostalPostCode] = useState(false);
	const [ customPostalAddress, setCustomPostalAddress ] = useState(!contact.postalSame);

	// upon unmount, clear changes to pending contact update
	useEffect(() => {
		return () => {
			dispatch(resetPendingUpdate());
			dispatch(resetPostCodeMatches());
		}
	}, [ dispatch ]);

	useEffect(()=>{
		if(showError){
			handleScrollToError();
		}
	}, [showError])

	useEffect(()=> {
		handleValidation({
			modeSwitch: true,
			residential: manualAddressMode		
		});		
	}, [manualAddressMode]);

	useEffect(()=> {
		handleValidation({
			modeSwitch: true,			
			postal: manualPostalAddressMode
		});		
	}, [manualPostalAddressMode]);

	// If the postcode is not valid according to the API, mark postcode invalid 
	useEffect(()=> {
		if (manualAddressMode && hasSearchedPostCode) {			
			if (!residentialAddressPostcodeValid){				
				markInvalid(formRef.current.querySelector('.field-contact-postcode'));
			} else
				markValid(formRef.current.querySelector('.field-contact-postcode'));
		}
		if (manualPostalAddressMode && hasSearchedPostalPostCode) {			
			if (!postalAddressPostcodeValid)
				markInvalid(formRef.current.querySelector('.field-contact-postal-postcode'));
			else
				markValid(formRef.current.querySelector('.field-contact-postal-postcode'));
		}
	}, [manualAddressMode, manualPostalAddressMode, residentialAddressPostcodeValid, postalAddressPostcodeValid]);

	const handleToggleAddressMode = () => {
		if(manualAddressMode){
			dispatch(resetPendingResidentialUpdate());
		}
		setManualAddressMode(!manualAddressMode);
	};

	const handleTogglePostalAddressMode = () => {
		if(manualPostalAddressMode){
			dispatch(resetPendingPostalUpdate());
		}
		setManualPostalAddressMode(!manualPostalAddressMode);
	}

	const handleAddressLookupComplete = (isResidentialSearch) => {
		if(isResidentialSearch){
			markValid(document.querySelector('.field-contact-address-lookup'));
		} else {
			markValid(document.querySelector('.field-contact-postal-address-lookup'));
		}
	};

	const handleToggleCustomPostalAddress = () => {
		if (customPostalAddress === true) {
			dispatch(updateContactCopyResidentialToPostal());
		}
		setCustomPostalAddress(!customPostalAddress);
	};

	const handleUpdateField = (e) => {
		var name = e.target.name;
		var val  = e.target.name === "ResidentialAddress.PostCode" || e.target.name === "PostalAddress.PostCode" ? e.target.value.toUpperCase() : e.target.value;
		if((e.target.name === "ResidentialAddress.PostCode" || e.target.name === "PostalAddress.PostCode") && val.replace(/\s/g).length === 6){
			val = val.substring(0, 3) + " " + val.substring(3, 6);
		}

		if(name === "HomePhone" || name === "MobilePhone" ){
			name = name === "HomePhone" ? "MobilePhone" : "HomePhone";
		}
		dispatch(updateContactField({ name: name, value: val }));
	};

	const handleNumericOnlyInput = (e) => {
		if(e.key !== '-'){
			preventNonNumericInput(e)
		}
	}

	const handleScrollToInvalid = () => {
		// Scroll first invalid field into view
		const firstInvalid = formRef.current.querySelectorAll('.field.invalid');
		if (firstInvalid.length) firstInvalid[0].scrollIntoView();
	}

	const handleScrollToError = () => {
		const errorMessage = formRef.current.querySelector(".flash-message");
		if(errorMessage !== null) errorMessage.scrollIntoView();
	}

	const handleValidation = (e) => {

		// Check postcode against API
		// This fires the async request; outcome is displayed by useEffect above
		if(e){
			if ((e.modeSwitch && e.residential) || (e.target && e.target.name === 'ResidentialAddress.PostCode')) {
				dispatch(getPostcodeMatches({ postcode: e.modeSwitch ? contact.ResidentialAddress.PostCode : e.target.value, isResidential: true }));
				setHasSearchedPostCode(true);
			} 
			else if ((e.modeSwitch !== undefined && e.postal) || (e.target && e.target.name === 'PostalAddress.PostCode')) {
				dispatch(getPostcodeMatches({ postcode: e.modeSwitch ? contact.PostalAddress.PostCode : e.target.value, isResidential: false }));
				setHasSearchedPostalPostCode(true);
			}
		}

		if (!validationActive.current) return;
		let isValid = true;

		// Residential Address
		isValid = validateResidentialAddress(formRef, contact, manualAddressMode);

		if(isValid) {
			markValid(formRef.current.querySelector('.field-contact-address-lookup'));
		}

		// PostalAddress
		let isPostalValid = validatePostalAddress(formRef, contact, manualPostalAddressMode);
		if(isPostalValid){
			markValid(formRef.current.querySelector('.field-contact-postal-address-lookup'))
		}

		if(isValid){
			isValid = isPostalValid;
		}

		// Mobile Phone
		if (contact.HomePhone.replace(/-/g, '').match(/^\d{10}$/)){
			markValid(formRef.current.querySelector('.field-contact-mobile'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-mobile'));
			isValid = false;
		}

		// Home Phone
		if (contact.MobilePhone.replace(/-/g, '').match(/^\d{10}$/)){
			markValid(formRef.current.querySelector('.field-contact-landline'));
		}
		else if (contact.MobilePhone.replace(/-/g, '').match(/^$/)) { // empty
			markValid(formRef.current.querySelector('.field-contact-landline'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-landline'));
			isValid = false;
		}

		// Email
		if (contact.Email.match(/^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/)) {
			markValid(formRef.current.querySelector('.field-contact-email'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-email'));
			isValid = false;
		}

		return isValid;
	};
	
	const handlePostcodeValidation = () => {
		// dispatch(getPostcodeMatches()
	}

	const handleSubmitChanges = () => {
		validationActive.current = true;
		if (handleValidation() === true) {
			dispatch(updateCustomerContacts());
		}
		else {
			handleScrollToInvalid();
		}
	}
	
	const handleCancel = () => {
		navigate('/contacts');
	}

	// Render
	return (
		<div className='form form-contact-details' ref={formRef}>
			{ showError && (
				 <FlashMessage message={updateContactFailureMessage} error={true} setHtml={true}/>
			)}
{/* 
			<div className='field field-contact-name'>
				<div className='help-label'>
					<label htmlFor='contact-name'>Contact Name</label>
					<div className='info-icon'></div>
					<div className='contact-name-tooltip' dangerouslySetInnerHTML={{ __html: contactNameChangeHelpText }}></div>
				</div>
				<div className='input-container'>
					<input name='contact-name' value={contact.FirstName + ' ' + contact.LastName } disabled	/>
					<div className='padlock-icon'></div>
				</div>
			</div> */}
				
			{ manualAddressMode === false && (<>
			
				<div className='field field-contact-address-lookup'>

					<label htmlFor='addressLookup'>Civic Address</label>

					<AddressLookup address={residentialAddress} isResidential={true} onComplete={handleAddressLookupComplete} matchPostalAddress={!customPostalAddress} />
			
				</div>

				<button className='btn-link address-mode-button' onClick={handleToggleAddressMode}>Can't find address?</button>
			
			</>)}

			{ manualAddressMode === true && (<>
			
				<div className='field field-contact-address'>
					<label htmlFor='ResidentialAddress.AddressLine'>Civic Address</label>
					<input name='ResidentialAddress.AddressLine' placeholder='Enter your street address' value={contact.ResidentialAddress.AddressLine} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid home address</div>
				</div>

				<div className='field field-contact-suburb'>
					<label htmlFor='ResidentialAddress.Suburb'>City</label>
					<input name='ResidentialAddress.Suburb' placeholder='City' value={contact.ResidentialAddress.Suburb} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid suburb</div>
				</div>

				<div className='field field-contact-state'>
					<label htmlFor='state'>Province</label>
					<select name='ResidentialAddress.State' value={contact.ResidentialAddress.State} onChange={handleUpdateField} onKeyUp={handleValidation}>
						{Object.keys(states).map((stateAbbrev, index) => (
							<option key={index} value={stateAbbrev}>{states[stateAbbrev]}</option>
						))}
					</select>
				</div>

				<div className='field field-contact-postcode'>
					<label htmlFor='postcode'>Postal Code</label>
					<input name='ResidentialAddress.PostCode' placeholder='Postal Code' value={contact.ResidentialAddress.PostCode} maxLength={7} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid postal code</div>
				</div>

				<button className='btn-link address-mode-button' onClick={handleToggleAddressMode}>Search for address</button>

			</>)}
			
			<div className='field field-contact-postal-address-container'>

				<label htmlFor='postal-toggle'>Postal Address</label>

				<div className='field field-contact-postal-toggle'>
					<input type='checkbox' checked={!customPostalAddress} onChange={handleToggleCustomPostalAddress} />
					<span>Same as Civic Address</span>
				</div>

				{ manualPostalAddressMode === false && customPostalAddress === true && <>
					<AddressLookup className={'postal-address-lookup'} address={postalAddress} isResidential={false} onComplete={handleAddressLookupComplete} />
					<button className='btn-link address-mode-button postal-address-mode-button' onClick={handleTogglePostalAddressMode}>Cant find postal address?</button>
				</> }

				{ manualPostalAddressMode === true && customPostalAddress === true && (<>
				
					{/* <div className='field field-country-selection'>
						<label htmlFor='PostalAddress.CountryId'>Country</label>
						<select name="PostalAddress.CountryId" value={contact.PostalAddress.CountryId} onChange={handleUpdateField}>
							{Object.keys(countries).map((countryId) => (
								<option value={countryId} key={countryId}>{countries[countryId]}</option>
							)) }
						</select>
					</div> */}

					<div className='field field-contact-postal-address'>
						<label htmlFor='PostalAddress.AddressLine'>Postal Address</label>
						<input name='PostalAddress.AddressLine' placeholder='Enter your street address' value={contact.PostalAddress.AddressLine} onChange={handleUpdateField} onKeyUp={handleValidation} />
						<div className='invalid-message'>Please enter a valid address</div>
					</div>

					<div className='field field-contact-postal-suburb'>
						<label htmlFor='PostalAddress.Suburb'>City</label>
						<input name='PostalAddress.Suburb' placeholder='City' value={contact.PostalAddress.Suburb} onChange={handleUpdateField} onKeyUp={handleValidation}  />
						<div className='invalid-message'>Please enter a valid city</div>
					</div>

					<div className='field field-contact-postal-state'>
						<label htmlFor='field field-contact-postal-state'>Province</label>
						{/* {contact.PostalAddress.CountryId  === defaultCountryId && */}
							<select name='PostalAddress.State' value={contact.PostalAddress.State} onChange={handleUpdateField}>
								{Object.keys(states).map((stateAbbrev, index) => (
									<option key={index} value={stateAbbrev}>{states[stateAbbrev]}</option>
								))}
							</select>
						{/* } */}

						{/* {contact.PostalAddress.CountryId  !== defaultCountryId && 
							<input name={'PostalAddress.State'} value={contact.PostalAddress.State} onChange={handleUpdateField}/>
						} */}
					</div>

					<div className='field field-contact-postal-postcode'>
						<label htmlFor='PostalAddress.PostCode'>Postal Code</label>
						{/* {contact.PostalAddress.CountryId === defaultCountryId && */}
							<>
								<input name='PostalAddress.PostCode' inputMode='numeric' placeholder='Postal Code' maxLength={7} value={contact.PostalAddress.PostCode} onChange={handleUpdateField} onKeyUp={handleValidation} />
								<div className='invalid-message'>Please enter a valid postal code</div>
							</>
						{/* } */}

						{/* {contact.PostalAddress.CountryId !== defaultCountryId && 
							<input name='PostalAddress.PostCode' inputMode='numeric' placeholder='Postal Code' value={contact.PostalAddress.PostCode} onChange={handleUpdateField} />
						} */}
					</div>

					<button className='btn-link address-mode-button' onClick={handleTogglePostalAddressMode}>Search for postal address</button>
				</>)}

			</div>
			

			<div className='field field-contact-mobile'>
				<label htmlFor='MobilePhone'>Cell Phone Number</label>
				<input name='MobilePhone' inputMode='numeric' placeholder='XXX-XXX-XXXX' value={contact.HomePhone} maxLength={12} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid cell phone number</div>
			</div>

			<div className='field field-contact-landline'>
				<label htmlFor='HomePhone'>Landline Phone Number</label>
				<input name='HomePhone' inputMode='numeric' placeholder='XXX-XXX-XXXX' value={contact.MobilePhone} maxLength={12} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid home phone number</div>
			</div>

			<div className='field field-contact-email'>
				<label htmlFor='Email'>Email</label>
				<input name='Email' inputMode='email' placeholder='you@domain.com' value={contact.Email} onChange={handleUpdateField} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid email address</div>
			</div>

			<p className='terms' dangerouslySetInnerHTML={{ __html: editContactDetailsTermsHtml }}></p>

			<button className='btn form-btn btn-rounded submit' onClick={handleSubmitChanges}>Save contact details <span className='chevron-r'></span></button>

			<button className='btn form-btn cancel secondary btn-rounded submit' onClick={handleCancel}>Cancel</button>

			<br /><br />

			{ spinner && <SpinnerOverlay message='Updating your contact information...'/> }

		</div>
		
	);
};

// Exports
export default ContactDetails;
