
// Externals
import { createSlice } from '@reduxjs/toolkit';
import { toTitleCase } from '../../util/formats';

// Actions 
import { getAddressMatches, getFormattedAddress, getPostcodeMatches, resetAddressMatches, resetPostCodeMatches } from '../actions/address';

// Initial State
const initialState = {
	addressMatches: [],
	postalAddressMatches: [],
	postcodeMatches: [],
	postalPostcodeMatches: []
};

// Address Lookup Reducer
const address = createSlice({
	name: 'address',
	initialState,
	reducers: {},
	extraReducers: (builder) => {

		// Store the address lookup matches 
		builder.addCase(getAddressMatches.pending, (state, action) => {
			//
		});
		builder.addCase(getAddressMatches.rejected, (state, action) => {
			//
		});
		builder.addCase(getAddressMatches.fulfilled, (state, action) => {
			if(action.payload.isResidential){
				state.addressMatches = action.payload.addresses.map(address => toTitleCase(address));
			} else {
				state.postalAddressMatches = action.payload.addresses.map(address => toTitleCase(address));
			}
		});
	
		// Store the postcode lookup matches 
		builder.addCase(getPostcodeMatches.pending, (state, action) => {
			//
		});
		builder.addCase(getPostcodeMatches.rejected, (state, action) => {
			//
		});
		builder.addCase(getPostcodeMatches.fulfilled, (state, action) => {
			const matches = action.payload.postcodes.map(item => item.split(';')).map(item => ({
				[item[0].replace(/\s/, '')]: item[2]
			}));
			if (action.payload.isResidential) {
				state.postcodeMatches = matches;
			}
			else {
				state.postalPostcodeMatches = matches;
			}
		});

		// Receive the formatted address, after user makes a choice from the lookup
		builder.addCase(getFormattedAddress.pending, (state, action) => {
			//
		});
		builder.addCase(getFormattedAddress.rejected, (state, action) => {
			//
		});
		builder.addCase(getFormattedAddress.fulfilled, (state, action) => {
			// state.addressMatches = action.payload;
			// this return data is captured in middleware/contact.js
		});

		builder.addCase(resetAddressMatches, (state, action) => {
			state.addressMatches = initialState.addressMatches;
			state.postalAddressMatches = initialState.postalAddressMatches;
			//state.postcodeMatches = initialState.postcodeMatches;
			//state.postalPostcodeMatches = initialState.postalPostcodeMatches;
		});

		builder.addCase(resetPostCodeMatches, (state) => {
			state.postcodeMatches = initialState.postcodeMatches;
			state.postalPostcodeMatches = initialState.postalPostcodeMatches;
		})

	}
});

// Exports
export default address.reducer;
