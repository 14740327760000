// Externals
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { customerLogin, validateOTP, resendOTP, customerLoginCancel, clearErrorMessage } from '../../store/actions/auth';
import { dobHelpTextSelector, loginFailureMessageSelector, mobileHelpTextSelector, emailHelpTextSelector } from '../../store/selectors/brand';

// Utils
import useHasChanged from '../../util/useHasChanged';
import useInterval from '../../util/useInterval';
import { clearInvalid, clearValid, markValid, markInvalid, handleNumericOnlyInput, dayAndMonthValidation, preventNonNumericInput } from '../../util/validate';

// Components
import OTPField from '../Forms/OTPField';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';

// Styles
import './Login.scss';

// Login Form
const Login = () => {

	const dispatch = useDispatch();

	// ID Switcher
	const [ isEmailId, setIsEmailId ] = useState(false);

	// Mobile Number
	const [ mobileNumber, setMobileNumber ] = useState('');
	const [ mobileNumberValid, setIsValidMobileNumber] = useState(false);

	// Email Address
	const [ emailAddress, setEmailAddress ] = useState('');
	const [ emailAddressValid, setIsValidEmailAddress] = useState(false);

	// DOB
	const [ dob_dd, setDob_dd ] = useState('');
	const [ dob_mm, setDob_mm ] = useState('');
	const [ dob_yyyy, setDob_yyyy ] = useState('');

	// OTP
	const [ otp, setOtp ] = useState(['', '', '' , '', '' , '']);

	const mobileGroupRef = useRef(null);
	const mobileFieldRef = useRef(null);
	const emailGroupRef = useRef(null);
	const emailFieldRef = useRef(null);
	const dobGroupRef = useRef(null);
	const ddRef = useRef(null);
	const mmRef = useRef(null);
	const yyyyRef = useRef(null);
	const mobileContinueButtonRef = useRef(null);
	const otpContinueButtonRef =  useRef(null);
	const otpGroupRef = useRef(null);
	const [otp0, focusOtp0] = useFocus();
	const [otp1, focusOtp1] = useFocus();
	const [otp2, focusOtp2] = useFocus();
	const [otp3, focusOtp3] = useFocus();
	const [otp4, focusOtp4] = useFocus();
	const [otp5, focusOtp5] = useFocus();
	

	const otpReferences = useMemo( () => { return [otp0, otp1, otp2, otp3, otp4, otp5] }, [otp0, otp1, otp2, otp3, otp4, otp5]);
	const otpFocusFunctions = [focusOtp0, focusOtp1, focusOtp2, focusOtp3, focusOtp4, focusOtp5];

	// resend OTP counter
	const [ otpCountdown, setOtpCountdown ] = useState(null);
	const [ otpInterval, setOtpInterval ] = useState(0);

	useInterval(() => {
		if (otpCountdown > 0) {
			setOtpCountdown(otpCountdown - 1);
		}
		else {
			setOtpInterval(0);
		}
	}, otpInterval);

	useEffect(() => {
		if (otpCountdown > 0) {
			setOtpInterval(1000);
		}
		else {
			setOtpInterval(0);
		}
	}, [ otpCountdown ]);

	const spinner = useSelector(state => state.auth.loading);
	const customerLoginId = useSelector(state => state.auth.customerLoginId);
	const showErrorMessage = useSelector(state => state.auth.showErrorMessage);
	const showTimeoutMessage = useSelector(state => state.auth.showTimeoutMessage);
	const showExpiredOtpMessage = useSelector(state => state.auth.showExpiredOtpMessage);
	const showErrorMessageHasChanged = useHasChanged(showErrorMessage);
	const errorMessage = useSelector(loginFailureMessageSelector);
	const mobileHelpText = useSelector(mobileHelpTextSelector);
	const emailHelpText = useSelector(emailHelpTextSelector);
	const dobHelpText = useSelector(dobHelpTextSelector);

	useEffect(()=>{
		if(!customerLoginId) return;
		if (otpCountdown === null) {
			focusOtp0();
		}
	}, [customerLoginId, focusOtp0, otpCountdown]);

	// scroll to top when OTP is prompted (for mobile)
	useEffect(() => {
		if (customerLoginId) {
			window.scrollTo({ top: 0, behaviour: 'smooth'});
		}
	}, [ customerLoginId ]);

	useEffect(() => {
		if(mobileNumber.length >= 10){
			validateMobileNumber(mobileNumber, true);
			dispatch(clearErrorMessage());
		}
	}, [mobileNumber])

	const setValue = (e) => {
		if (e.target.name === 'mobileNumber') {
			setMobileNumber(e.target.value);
		} 
		else if(e.target.name === 'emailAddress'){
			setEmailAddress(e.target.value);
		}
		else if (e.target.name === 'dob_dd') {
			setDob_dd(e.target.value);
		}
		else if (e.target.name === 'dob_mm') {
			setDob_mm(e.target.value);
		}
		else if (e.target.name === 'dob_yyyy') {
			setDob_yyyy(e.target.value);
		}
		else if (e.target.name === 'otp') {
			setOtp(e.target.value);
		}
	};

	const handleKeyDown = (e) => {
		if(e.target.name === 'emailAddress'){
			dispatch(clearErrorMessage());
			return;
		}

		if(e.key !== '-' || e.target.name === 'dob_dd' || e.target.name === 'dob_mm' || e.target.name === 'dob_yyyy'){
			preventNonNumericInput(e);
		}

		const newValue = handleNumericOnlyInput(e);

		if(e.target.name === 'mobileNumber'){
			if(showErrorMessage || showExpiredOtpMessage)
				dispatch(clearErrorMessage());
			validateMobileNumber(newValue, true);
		}

		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm' || e.target.name === 'dob_yyyy'){
			if(showErrorMessage || showExpiredOtpMessage)
				dispatch(clearErrorMessage());
			validateDob(newValue, e.target.name);
		}
		
	}

	const handleKeyUp = (e) => {
		if(e.which === 13 && mobileContinueButtonRef.current){
			var mobileValid = validateMobileNumber(mobileNumber, false);
			var emailValid = validateEmailAddress(emailAddress, false);
			var dobValid = validateDob(dob_yyyy, 'dob_yyyy');
			if(e.target.name === 'emailAddress' && emailValid && !dobValid && isEmailId){
				yyyyRef.current.focus();
			} else if(e.target.name === 'mobileNumber' && mobileValid && !dobValid && !isEmailId){
				yyyyRef.current.focus();
			} else if(e.target.name === 'dob_yyyy' && !dobValid && mmRef.current){
				mmRef.current.focus();
			} else if(e.target.name === 'dob_mm' && !dobValid && ddRef.current) {
				ddRef.current.focus();
			} else {
				mobileContinueButtonRef.current.click()
				return
			}
			
		}

		if(e.target.name === 'emailAddress'){
			if(showErrorMessage)
				dispatch(clearErrorMessage());
			validateEmailAddress(e.target.value, true);
		}

	}

	const handleOnBlur = (e) => {
		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm'){
			if(e.target.value.length === 1) {
				if(e.target.name === 'dob_dd' && e.target.value !== '0'){
					setDob_dd('0' + e.target.value);
				} else if(e.target.name === 'dob_mm' && e.target.value !== '0'){
					setDob_mm('0' + e.target.value);
				}
			}
		} else if(e.target.name === 'dob_yyyy'){
			if(dobGroupRef.current && !e.target.checkValidity()){
				markInvalid(dobGroupRef)
			}
		}
	}

	const handleOTPChange = (index) => (e) => {
		const newOtpVal = otp;
		newOtpVal[index] = e.target.value;
		setOtp(newOtpVal);
		if(e.target.value.length === 1 && index !== 5){
			otpFocusFunctions[index + 1]();
		}
	}

	const handleOTPKeyDown = (index) => (e) =>{

		if(showErrorMessage || showExpiredOtpMessage){
			dispatch(clearErrorMessage());
		}

		preventNonNumericInput(e);

		if(e.target.value.length === 0 && e.which === 8 && index !== 0){
			otpFocusFunctions[index - 1]();
		}

		if(e.which === 13 && otpContinueButtonRef.current){
			otpContinueButtonRef.current.click();
		}

	}

	const handleDobKeyUp = (e) => {
		var exemptModifiers = ['Tab', 'Shift', 'Alt', 'Control', 'CapsLock', 'Escape', 'Delete', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];
		if(e.target.name === 'dob_yyyy' && e.target.value.length === 4 
			&& mmRef.current 
			&& !exemptModifiers.includes(e.key)){
			mmRef.current.focus();
		} else if (e.target.name === 'dob_mm' && e.target.value.length === 2 
			&& ddRef.current 
			&& !exemptModifiers.includes(e.key)){
			ddRef.current.focus();
		}

		handleKeyUp(e);
	}

	const handleOTPKeyUp = (e) => {
		validateOtp(false);
	}

	const handleLogin = () => {
		if(!isEmailId && !validateMobileNumber(mobileNumber, false))
			return
		
		if(isEmailId && !validateEmailAddress(emailAddress, false))
			return

		if(!validateDob(dob_dd, 'dob_dd', true))
			return

		const args = {
			...(!isEmailId && {mobileNumber: mobileNumber.replace(/-/g, '')}),
			...(isEmailId && {emailAddress: emailAddress}),
			dob: dob_yyyy + '-' + dob_mm + '-' + dob_dd
		};

		dispatch(customerLogin(args));
	}

	const handleLoginOTP = () => {
		if(validateOtp(true)){
			dispatch(validateOTP(otp.join('')));
		}
	}

	const handleResendOTP = () => {
		dispatch(resendOTP());
		setOtpCountdown(30);
	}

	const validateEmailAddress = (value, didChange) => {
		if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
			if(!showErrorMessage){
				markValid(emailGroupRef);
				clearInvalid(emailGroupRef);
			} else {
				clearValid(emailFieldRef);
			}
			if(!emailAddressValid){
				setIsValidEmailAddress(true);
			}
			
			if(!didChange) {
				return true;
			}
		} else {
			clearValid(emailGroupRef);
			markInvalid(emailGroupRef);
			if(!didChange){
				markInvalid(emailGroupRef);
				return false;
			}
		}
	}

	const validateMobileNumber = (value, didChange) => {
		const trimmedValue = value.replace(/-/g, '');
		if(/^\d{10}$/.test(trimmedValue)){
			if(!showErrorMessage){
				markValid(mobileGroupRef);
				clearInvalid(mobileGroupRef);
			} else {
				clearValid(mobileFieldRef);
			}
			if(!mobileNumberValid){				
				setIsValidMobileNumber(true);
			}
			
			if(!didChange) {
				return true;
			}
		} else {
			clearValid(mobileGroupRef);			
			if(!didChange){
				markInvalid(mobileGroupRef);
				return false;
			}
		}
		
	}

	const validateDob = (value, target, isSubmit) =>{
		var isValid = null;
		if((ddRef.current && ddRef.current.checkValidity()) || (target === 'dob_dd' && value)){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
	
			//Handles date being out of bounds i.e. < 1 or > 31
			if(dd !== 0 || isSubmit){
				isValid = dayAndMonthValidation(dd, null, null);
			}

			if(isValid == null && 
				((mmRef.current && mmRef.current.checkValidity()) || (target === 'dob_mm' && value)) &&
				((yyyyRef.current && yyyyRef.current.checkValidity()) || 
					(target === 'dob_yyyy' && value.length === 4) || isSubmit)){
				const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
				const yyyy = target === 'dob_yyyy' ? parseInt(value) : parseInt(dob_yyyy);

				if(mm !== 0 || isSubmit){			
					isValid = dayAndMonthValidation(dd, mm, yyyy);
				}
			}
			
		}

		if((isValid === null) && ((ddRef.current && ddRef.current.checkValidity()) || (target === 'dob_dd' && value))){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
			const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
			if(dd !== 0 && mm !== 0 && !isSubmit){
				isValid = dayAndMonthValidation(dd, mm, null);	
			}
		}

		if(((ddRef.current && ddRef.current.value.length < 2) || (mmRef.current && mmRef.current.value.length < 2) || (yyyyRef.current && yyyyRef.current.value.length < 4)) && isSubmit){
			isValid = false;
		}

		if(dobGroupRef.current){
			if(isValid === null) {
				clearValid(dobGroupRef);
				clearInvalid(dobGroupRef);
			} else if(!isValid){
				clearValid(dobGroupRef);
				markInvalid(dobGroupRef);
			}else{
				clearInvalid(dobGroupRef);
				markValid(dobGroupRef);
			}
		}

		return isValid;
	}

	const isLoginValidated = () => {
		if(isEmailId)
			return emailAddressValid;

		return mobileNumberValid;
	}

	const validateOtp = useCallback((isSubmit) => {
		if(otpGroupRef.current && otp0.current && otp1.current && otp2.current && otp3.current &&
			otp4.current && otp5.current){
			if(!showErrorMessage && 
				!isSubmit &&
				otp0.current.value.length === 1 &&
				otp1.current.value.length === 1 &&
				otp2.current.value.length === 1 && 
				otp3.current.value.length === 1 &&
				otp4.current.value.length === 1 &&
				otp5.current.value.length === 1) {
					clearInvalid(otpGroupRef);
					markValid(otpGroupRef);

			} else if(isSubmit && (!otp0.current.checkValidity() || !otp1.current.checkValidity() || !otp2.current.checkValidity() || !otp3.current.checkValidity() ||
				!otp4.current.checkValidity() || !otp5.current.checkValidity())){
					clearValid(otpGroupRef);
					markInvalid(otpGroupRef);
					return false;
			} else if(isSubmit) {
				clearInvalid(otpGroupRef);
				markValid(otpGroupRef);
				return true;
			} else {
				clearInvalid(otpGroupRef);
				clearValid(otpGroupRef);
			}
		}

		return true;
	}, [otp0, otp1, otp2, otp3, otp4, otp5, otpGroupRef, showErrorMessage])

	useEffect(() => {
		if(!customerLoginId) return;
		if((showErrorMessageHasChanged && showErrorMessage) || (showExpiredOtpMessage)){
			otpReferences.forEach(ref => {
				if(ref.current){
					ref.current.value = '';
				}
			});
			setOtp(['', '', '', '', '', '']);
			validateOtp();	
			focusOtp0();
		}
	}, [showErrorMessage, showErrorMessageHasChanged, otpReferences, showExpiredOtpMessage, validateOtp, focusOtp0]);

	useEffect(() => {
		if(showErrorMessage){
			clearValid(mobileGroupRef);
			clearValid(emailGroupRef);
			clearValid(dobGroupRef);
			clearValid(otpGroupRef);
		}
	}, [showErrorMessage])

	const handleCancel = useCallback(() => {
		setMobileNumber('');
		setEmailAddress('');
		setIsValidMobileNumber(false);
		setIsValidEmailAddress(false);
		setDob_dd('');
		setDob_mm('');
		setDob_yyyy('');
		dispatch(customerLoginCancel());
	}, [setMobileNumber, setIsValidMobileNumber, setEmailAddress, setIsValidEmailAddress, setDob_dd, setDob_mm, setDob_yyyy, dispatch]);

	useEffect(()=> {
		if(showExpiredOtpMessage && customerLoginId){
			handleCancel();
		}
	}, [showExpiredOtpMessage, customerLoginId, handleCancel])

	// Render
	return (
		<div className='form form-login'>

			{ showErrorMessage && <p className='error' dangerouslySetInnerHTML={{ __html: errorMessage }}></p> }
			{ (!showErrorMessage && showTimeoutMessage) && <p className='error'>Your session has expired.  Please login again to continue.</p> }
			{ showExpiredOtpMessage && <p className='error'>OTP expired. Please try again.</p>}
			{ !customerLoginId && (<>
				<div className="id-container">
					<div className="id-option">
					<input type="radio" name="id" value="mobile" checked={!isEmailId} onChange={(e)=> setIsEmailId(!e.target.checked)}/> 
					<label htmlFor="mobile">SMS</label></div>
					<div className="id-option">
					<input type="radio" name="id" value="email" checked={isEmailId} onChange={(e)=> setIsEmailId(e.target.checked)} /> 
					<label htmlFor="email">Email</label></div>
				</div>
				{!isEmailId && 
					<div ref={mobileGroupRef} className='field field-login-mobileNumber'>
						<div className='mobile-label label-container'>
							<label htmlFor='mobileNumber'>Cell Phone Number</label>
							<div className='info-icon'></div>
							<div className='tooltip' dangerouslySetInnerHTML={ { __html: mobileHelpText}}></div>
						</div>

						<input ref={mobileFieldRef} type='tel' inputMode="numeric" name='mobileNumber' value={mobileNumber} placeholder='XXX-XXX-XXXX' maxLength='12' pattern='\d{3}-?\d{3}-?\d{4}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} required />
						<span className='invalid-message'>Please enter your 10 digit cell phone number.</span>
					</div>
				}

				{isEmailId && 
					<div ref={emailGroupRef} className='field field-login-emailAddress'>
						<div className='email-label label-container'>
							<label htmlFor='emailAddress'>Email Address</label>
							<div className='info-icon'></div>
							<div className='tooltip' dangerouslySetInnerHTML={ { __html: emailHelpText}}></div>
						</div>

						<input ref={emailFieldRef} type='email' name='emailAddress' value={emailAddress} placeholder='XXXXX@XXXXX.XX' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} required />
						<span className='invalid-message'>Please enter your email address.</span>
					</div>
				}

				{((!isEmailId && mobileNumberValid) || (isEmailId && emailAddressValid)) &&
				
					<div ref={dobGroupRef} className='field field-login-dob'>
						<div className='dob-label label-container'>
							<label htmlFor='dob_dd'>Date of Birth</label>
							<div className='info-icon'></div>
							<div className='tooltip' dangerouslySetInnerHTML={ { __html: dobHelpText}}></div>
						</div>
						
						<div className='dob-fields'>
							<input ref={yyyyRef} type='text' inputMode="numeric" name='dob_yyyy' value={dob_yyyy} placeholder='YYYY' maxLength={4} pattern='\d{4}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
							<input ref={mmRef} type='text' inputMode="numeric" name='dob_mm' value={dob_mm} placeholder='MM' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
							<input ref={ddRef} type='text' inputMode="numeric" name='dob_dd' value={dob_dd} placeholder='DD' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
						</div>
						<span className='invalid-message'>Please enter a valid date of birth.<br/>e.g YYYY-MM-DD</span>
					</div>

				}
				
				<button ref={mobileContinueButtonRef} className='form-btn btn-rounded' onClick={(e) => { !isLoginValidated() ?  isEmailId ? validateEmailAddress(emailAddress, false) : validateMobileNumber(mobileNumber, false) : handleLogin()}}><>{!isLoginValidated() ? 'Continue' : 'Log In'}</><span className='chevron-r'/></button>

			</>)}

			
			{customerLoginId && (<>

				<div className='field-login-otp'>
					{!isEmailId &&
						<label htmlFor='otp'>Enter the 6-digit code you received on the following cell phone number:
							<div className='mobileNumber'><b>{ '***-***-* ' + mobileNumber.replace(/-/g, '').substring(7, 10)}</b></div>
						</label>
					}
					{isEmailId && 
						<label htmlFor='otp'>Enter the 6-digit code you received via your email address:
							<div className='mobileNumber'><b>{ emailAddress.substring(0, 4) + "*".repeat(emailAddress.split("@")[0].length - 3) + "@*****"}</b></div>
						</label>
					}
					

					<div ref={otpGroupRef} className='form-login-otp'>
						<div className='otp-field-container'>
							{otp.map((value, index) => (
								<OTPField key={index} index={index} value={value} handleOTPChange={handleOTPChange} handleOTPKeyDown={handleOTPKeyDown} handleOTPKeyUp={handleOTPKeyUp} reference={otpReferences[index]} />
							))}
						</div>
						<span className='invalid-message'>Please enter your 6 digit {isEmailId ? "Email" : "SMS" } code.</span>
					</div>
					
					<div className='resend-container'>
						<p>Need another verification code? </p>
						{(otpCountdown === 0 || otpCountdown === null) && <>&nbsp;<a href="#0" className='resend-link' onClick={handleResendOTP}>Resend</a></>}
						{otpCountdown > 0 && <span>&nbsp;{otpCountdown + 's'}</span>}
					</div>
				</div>
				<div className='login-btn-container'>
					<button className='form-btn btn-rounded cancel secondary' onClick={handleCancel}>Cancel</button>
					<button ref={otpContinueButtonRef} className='form-btn btn-rounded' onClick={handleLoginOTP}>Continue <span className='chevron-r'/></button>
				</div>
			</>)}
			
			{ spinner && <SpinnerOverlay message='Validating your credentials...'/> }

		</div>
	);
};

const useFocus = () =>{
	const htmlRef = useRef(null);
	const setFocus = () => { htmlRef.current && htmlRef.current.focus() }
	return [htmlRef, setFocus];
}

// Exports
export default Login;
