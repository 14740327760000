// Externals
import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import useInterval from '../../util/useInterval';

// Selectors
import { overduePaymentsBannerSelector } from '../../store/selectors/policy';
import { payNowPoliciesPendingPaymentSelector } from '../../store/selectors/payment';

// Actions
import { getPolicyOutstandingPayments } from '../../store/actions/policy';

// Components
import Container from '../Layout/Container';

// Utility
import { formatMoneyWithDecimal } from '../../util/formats';

// Styles
import './OverdueBanner.scss';

import { pendingPoll } from '../../store/constants';

// Dashboard
const OverdueBanner = () => {

	const overduePayments = useSelector(overduePaymentsBannerSelector);
	const pendingPayments = useSelector(payNowPoliciesPendingPaymentSelector);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = (policyNumber) => {
		navigate('/payment/' + policyNumber)
	};

	// if there are pending payments, keep checking for completion
	useInterval(() => {
		pendingPayments.forEach(policyNumber => {
			dispatch(getPolicyOutstandingPayments(policyNumber));
		})
	}, pendingPayments.length > 0 ? pendingPoll : null);

	// Render
	return overduePayments && (
			<div className='overdue-banner'>
			<Container>
				{ overduePayments.map((payment, index) => (
					<div className='banner' key={index}>
						<div className='message'>
							{ !payment.hasPending && (<>
								<div className='notification-bell' />
								<span className='message-content'>
									<b>
										{payment.overdueCount}
										{' '}
										{payment.overdueCount > 1 ? 'payments' : 'payment'}
										{' '}
										({ formatMoneyWithDecimal(payment.totalDue) })
									</b>
									{' '}
									{payment.overdueCount > 1 ? 'are' : 'is'}
									{' '}
									overdue for your
									{' '}
									<b>{payment.policyName}</b>
									{' '}
									policy
								</span>
							</>)}
							{ payment.hasPending && (<>
								<div className='notification-bell' />
								<span className='message-content'>
									<b>Processing</b> overdue payment for your
									{' '}
									<b>{payment.policyName}</b>
									{' '}
									policy
								</span>
							</>)}
						</div>
						{ !payment.hasPending && (
							<button onClick={() => handleClick(payment.policyNumber)} className='btn btn-secondary btn-rounded'>
								<span>Pay now</span> 
								<span className='chevron-r'></span>
							</button>
						)}
					</div>
				))}
			</Container>
		</div>
	);

};

// Exports
export default OverdueBanner;
