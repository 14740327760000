
// Externals
import { createSlice } from '@reduxjs/toolkit';

// Actions 
import { customerLogin,
	customerLogout,
	customerSessionCheck,
	customerSessionTimeout,
	validateOTP,
	resendOTP,
	customerLoginCancel,
	clearErrorMessage, 
	expiredOtp } from '../actions/auth';

// Initial State
const initialState = {
	customerLoginId: null,
	bearerToken: null,
	bearerTokenExpiresAt: null,
	loading: false,
	showErrorMessage: null,
	showTimeoutMessage: null, 
	showExpiredOtpMessage: null
};

// Results Reducer
const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {

		// Handle Step 1 Customer Login
		builder.addCase(customerLogin.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(customerLogin.rejected, (state, action) => {
			state.loading = false;
			state.showErrorMessage = true;
		});
		builder.addCase(customerLogin.fulfilled, (state, action) => {
			state.customerLoginId = action.payload;
			state.loading = false;
			state.showErrorMessage = null;
		});

		builder.addCase(customerLoginCancel, (state, action) => {
			state.customerLoginId = null;
			state.loading = false;
			state.showErrorMessage = null;
		});

		builder.addCase(clearErrorMessage, (state, action) => {
			state.showErrorMessage = false;
			state.showExpiredOtpMessage = false;
			state.showTimeoutMessage = false;
		})

		// Handle Step 2 Validate OTP
		builder.addCase(validateOTP.pending, (state, action) => {
			state.loading = true;
			state.showErrorMessage = false;
		});
		builder.addCase(validateOTP.rejected, (state, action) => {
			state.loading = false;
			state.showErrorMessage = true;
		});
		builder.addCase(validateOTP.fulfilled, (state, action) => {
			state.bearerToken = action.payload.SecurityToken;
			state.bearerTokenExpiresAt = action.payload.ExpiresAt;
			state.loading = false;
			state.showErrorMessage = null;
		});

		builder.addCase(expiredOtp, (state, action) => {
			state.showErrorMessage = null;
			state.showTimeoutMessage = null;
			state.showExpiredOtpMessage = true;
			state.loading = false;
		});

		// Handle Resend OTP
		builder.addCase(resendOTP.pending, (state, action) => {
			//
		});
		builder.addCase(resendOTP.rejected, (state, action) => {
			//
		});
		builder.addCase(resendOTP.fulfilled, (state, action) => {
			state.customerLoginId = action.payload;
		});

		// Handle Customer Logout
		builder.addCase(customerLogout, (state, action) => {
			state.customerLoginId = null;
			state.bearerToken = null;
		});

		// Confirm Customer Session Lifetime
		builder.addCase(customerSessionCheck, (state, action) => {
			// handled in middleware/auth.js 
			// (cannot dispatch actions here)
		});

		// Handle Customer Session Timeout
		builder.addCase(customerSessionTimeout, (state, action) => {
			state.customerLoginId = null;
			state.bearerToken = null;
			state.bearerTokenExpiresAt = null;
			state.showTimeoutMessage = true;
		});

	},
});

// Exports
export default auth.reducer;
