import React from "react";
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector, renewalLabelSelector } from '../../../store/selectors/brand';
import { policySummarySelector } from "../../../store/selectors/policy";

// Utility
import { formatDate } from '../../../util/formats';

// Components
import Table from "../../Layout/Table";
import TableRow from "../../Layout/TableRow";

const DetailPolicySummary = ({policy, isInactivePolicy}) => {
    const summary = useSelector(state => policySummarySelector(state, policy.PolicySummary.PolicyNumber));
	const sectionHeading = useSelector(state => headingSelector(state, 'policySummary'));
    const renewalLabel = useSelector(renewalLabelSelector);
    return (<>
        <div className='detail-heading'><h3>{sectionHeading}</h3></div>
        <Table className='policy-details-summary'>
                <TableRow heading={'Policy Number' } value={policy.PolicySummary.PolicyNumber}/>
                <tr className={'policy-owner-name'}><td key={1}>Policyowner</td><td key={2}><div>{policy.Owner.Title + ' ' + policy.Owner.FirstName + ' ' + policy.Owner.Lastname}</div></td></tr>
                <TableRow heading={'Policy Status'} value={policy.PolicyStatus.Description} />
                <TableRow heading={ 'Policy Start Date'} value={formatDate(policy.PolicySummary.FirstCollectionDate)} />
                { isInactivePolicy && policy.PolicySummary.PolicyTerminationDate &&
                    <TableRow heading={'Policy End Date'} value={formatDate(policy.PolicySummary.PolicyTerminationDate)} />
                }
                { !isInactivePolicy && policy.PolicySummary.RenewalDate && 
                    <TableRow heading={renewalLabel} value={formatDate(policy.PolicySummary.RenewalDate)} />
                }
                
         </Table>
    </>)
}

export default DetailPolicySummary;
