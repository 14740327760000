import React from 'react';

const TableRow = (props) =>{
    return(
        <>
        { !props.values &&
            <tr className={props.className}><td key={1}>{props.heading}</td><td key={2}>{props.value}</td></tr>
        }

        { props.values && props.values.length > 0 && !props.labels &&
            <tr className={props.className}>
                { props.values.map( (value, index) => (
                    <td key={index}>{value}</td>
                ))} 
            </tr>
        }
 
        { props.values && props.values.length > 0 && props.labels && props.labels.length === props.values.length &&
            <tr className={props.className}>
                { props.values.map( (value, index) => (
                    <td label={props.labels[index]} key={index}>{value}</td>
                ))} 
            </tr>
        }

        </>
    )
}

export default TableRow; 