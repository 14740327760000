// Actions
import { 
	addPolicySummaryFromContact, 
	getPolicyDetail, 
	getPolicyOutstandingPayments,
	setPolicyStatusPaymentPending,
	setPolicyCount,
	updatePolicyStatus } from '../actions/policy';

import { getPolicyDocumentList } from '../actions/documents';

import {
	markPolicyPayNowPending,
	clearPolicyPayNowPending } from '../actions/payment';

	
// Config
import config from '../../config';

// Documents Feature Flag
const documentsFeature = config.DOCUMENTS_FEATURE_FLAG;

// Policy Middleware
const policyMiddleware = store => next => action => {
	if (action.type === 'contact/GET_CUSTOMER_DETAIL/fulfilled') {

		next(action);

		// If policies are not yet populated..
		const p = store.getState().policy;
		if (Object.keys(p.policySummaries).length === 0 || Object.keys(p.policyDetails).length === 0) {

			// Extract policies from the contactdetail response, and dispatch to policy reducer
			const policies = action.payload.Policies;
			store.dispatch(setPolicyCount(policies.length));
			policies.forEach(policy => {

				// populate policy summary
				store.dispatch(addPolicySummaryFromContact(policy));
				
				// fetch detailed policy
				store.dispatch(getPolicyDetail(policy.PolicyNumber));

			});
		}
	}
	else if (action.type === 'policy/ADD_POLICY_SUMMARY_FROM_CONTACT') {

		next(action);
		
		// get list of overdue payments for policy
		const policyNumber = action.payload.PolicyNumber;
		const productCode = action.payload.Product.ProductCode;
		const saleCompletedDate = action.payload.SaleCompletedDate.split("T")[0];
		const brandId = action.payload.Product.Brand.BrandId;

		if(action.payload.PolicyStatusId < 50){
			store.dispatch(getPolicyOutstandingPayments(policyNumber));
		}

		// get list of documents available for policy
		if (documentsFeature) store.dispatch(getPolicyDocumentList({
				PolicyNumber: policyNumber, 
				ProductCode: productCode, 
				SaleCompletedDate: saleCompletedDate, 
				BrandId: brandId
			})
		);

	}
	else if (action.type === 'policy/GET_PAYMENTS_OUTSTANDING/fulfilled') {

		next(action);
		
		const policyNumber = action.payload.policyNumber;

		if (action.payload.markPolicyPayNowPending) {
			store.dispatch(markPolicyPayNowPending(policyNumber));
			store.dispatch(setPolicyStatusPaymentPending(policyNumber));
		}
		if (action.payload.clearPolicyPayNowPending) store.dispatch(clearPolicyPayNowPending(policyNumber));
		if (action.payload.updatePolicyStatus) store.dispatch(updatePolicyStatus(policyNumber));

	}
	else if (action.type === 'policy/GET_POLICY_DETAIL/fulfilled') {

		next(action);
		
		// if pay now is pending, update the policy status inside the policy detail object
		const policyNumber = String(action.payload.PolicySummary.PolicyNumber);
		if (store.getState().payment.payNowPoliciesPendingPayment.includes(policyNumber)) {
			store.dispatch(setPolicyStatusPaymentPending(policyNumber));
		}

	}
	else {
		return next(action);
	}

};

// Exports
export default policyMiddleware;
