// Externals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Selectors
import { contactSelector, contactEditableSelector } from '../store/selectors/contact';
import { headingSelector } from '../store/selectors/brand';

// Assets
import { ReactComponent as EditIcon } from '../static/pencil-solid.svg';

// Components
import FlashMessage from '../components/Widgets/FlashMessage';
import Container from '../components/Layout/Container';
import OverdueBanner from '../components/Widgets/OverdueBanner';

// Styles
import './ContactDetails.scss';

// Update Contact Details
const ContactDetails = () => {

    const contact = useSelector(contactSelector);
	const contactEditable = true; // useSelector(contactEditableSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myContactDetails'));
	const pageSubHeading = useSelector(state => headingSelector(state, 'customerContactDetails'));

	const [ flashMessage, setFlashmessage ] = useState(false);

	useEffect(() => {
		// show success flash message
		const p = new URLSearchParams(document.location.search);
		if (p.get('success')) setFlashmessage(true);
	}, []);

	return (<>

		<OverdueBanner />
		
		<Container>
			<div className='page-contact page-contact-details'>

				<h1>{pageHeading}</h1>

				{ flashMessage && <FlashMessage message={'Your contact details have been updated'} /> }

				<div className='header-container'>
					<h2>{pageSubHeading}</h2>

					{contactEditable && <div className='edit-button'>
							<Link to='/contacts/update'><EditIcon />Edit details</Link>
						</div>
					}

				</div>
				<dl>
					{/* <dt>Contact Name</dt>
					<dd><div className='contact-name-display'>{contact.FirstName + " " + contact.LastName}<div className='padlock-icon'></div></div></dd> */}
					<dt>Civic Address</dt>
					<dd>{contact.homeAddress}</dd>

					<dt>City</dt>
					<dd>{contact.townCity}</dd>

					<dt>Province</dt>
					<dd>{contact.state}</dd>

					<dt>Postal Code</dt>
					<dd>{contact.postcode}</dd>

					<dt className='pad'>Postal Address</dt>
					<dd className='pad'>{contact.postalAddress}</dd>

					<dt>Cell Phone Number</dt>
					<dd>{contact.landlinePhone}</dd>

					<dt>Landline Phone Number</dt>
					<dd>{contact.mobilePhone}</dd>

					<dt>Email</dt>
					<dd className='email'>{contact.email}</dd>
				</dl>
			</div>
		</Container>
	</>);
};

export default ContactDetails;
