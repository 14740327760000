// Externals

// Store
import history from '../history';

// Actions
import { resetAddressMatches } from '../actions/address';
import { getCustomerDetail, updateContactFromAusPostFormattedAddress } from '../actions/contact';

// Utility
import { formatAddressCA } from '../../util/formats';

// Contact Middleware
const contactMiddleware = store => next => action => {
	if (action.type === 'contact/UPDATE_CUSTOMER_CONTACTS/fulfilled') {

		// refresh the customer detail
		store.dispatch(getCustomerDetail());

		// redirect
		history.replace('/contacts?success=1');

	}
	else if (action.type === 'address/GET_FORMATTED_ADDRESS/fulfilled') {

		// capture the AusPost formatted address and re-format for Evolve address format
		store.dispatch(updateContactFromAusPostFormattedAddress({ address: formatAddressCA(action.payload.address), isResidential: action.payload.isResidential, matchPostalAddress: action.payload.matchPostalAddress }));
		
		store.dispatch(resetAddressMatches());

	}
	return next(action);

};

// Exports
export default contactMiddleware;
