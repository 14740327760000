// Externals
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { allPoliciesLoadedSelector, validPoliciesSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';

// Components
import Container from '../components/Layout/Container';
import OverdueBanner from '../components/Widgets/OverdueBanner';
import PolicyHeader from '../components/Widgets/PolicyHeader';
import DocumentList from '../components/Documents/List';
import DocumentView from '../components/Documents/View';
import Spinner from '../components/Widgets/Spinner';

// Pages
import Error404 from '../pages/Error404';
import { allDocumentsLoadedSelector } from '../store/selectors/documents';

// Documents page
const Documents = () => {
	const { policyNumber } = useParams();
	const [queryParams] = useSearchParams();
	const validPolicies = useSelector(validPoliciesSelector);
	const allDocumentsLoaded = useSelector(allDocumentsLoadedSelector);
	const allPoliciesLoaded = useSelector(allPoliciesLoadedSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myDocuments'));

	if (allDocumentsLoaded && !validPolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	else return (<>
		{allDocumentsLoaded && allPoliciesLoaded && <PolicyHeader /> }

		{allDocumentsLoaded && allPoliciesLoaded && <OverdueBanner /> }

		<Container>
			<div className='page-view-documents'>

				<h1>{pageHeading}</h1>

				{ (!allDocumentsLoaded || !allPoliciesLoaded) && <Spinner />}

				{ !queryParams.has('docId') && allDocumentsLoaded && allPoliciesLoaded && <DocumentList policyNumber={policyNumber} /> }

				{ queryParams.has('docId') && allDocumentsLoaded && allPoliciesLoaded && <DocumentView policyNumber={policyNumber} documentNumber={queryParams.get('docId')} /> }

			</div>
		</Container>

	</>);

};

// Exports
export default Documents;
export { DocumentView };
