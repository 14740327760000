export const FiftyUpBrandHeadings = {
	myAccountLogin: 'My Account Login',
	myInsurancePolicies: 'My insurance policies',
	policyOverview: 'Policy overview',
	policySummary: 'Policy summary',
	paymentDetails: 'Payment details',
	livesInsured: 'Lives insured details',
	beneficiaryDetails: 'Beneficiary details',
	updateDetails: 'Update details',
	myPaymentDetails: 'My payment details',
	editPaymentDetails: 'Edit payment details',
	myContactDetails: 'My contact details',
	customerContactDetails: 'Customer contact details',
	editContactDetails: 'Edit contact details',
	makePayment: 'Make a payment',
	myDocuments: 'My inbox'
};

export const FiftyUpBrandMessages = {
    changeToPolicyCTA: 'You can update your contact and payment details online. If you would like to make additional changes to your policy, please contact us on <a href="tel:18443399661">1-844-339-9661</a>, ' +
    'between 8am and 8pm (ET) Monday to Friday, and we’ll be happy to help.',
    chequeMethodChangeCTA: 'Due to your current payment method, you will need to contact us on <a href="tel:18443399661">1-844-339-9661</a>, ' +
    'between 8am and 8pm (ET) Monday to Friday, to make changes to your payments details for this policy.',
    ctaContactDetails: 'Update email, postal address and phone number.',
    ctaPaymentDetails: 'Update payment card or bank account details.',
    ctaMakeAPayment: 'Pay overdue payments by credit card.',
    ctaWereHereToHelpTitle: 'We’re here to help',
    ctaWereHereToHelpText: 'Can’t find what you are looking for? Please contact us on 1-844-339-9661, between 8am and 8pm (ET) Monday to Friday, and we’ll be happy to help.',
    loginFailureMessage: 'Your login details are incorrect. Please try again or call us on <a href="tel:18443399661">1-844-339-9661</a>, between 8am and 8pm (ET) Monday to Friday.',
    mobileHelpText: 'This is the cell phone number we have on file for your policy',
    emailHelpText: 'This is the email address we have on file for your policy',
    dobHelpText: 'This is the date of birth of the policyowner as listed on your policy schedule',
    editContactDetailsTerms: 'Any changes made to your contact details will be applied across all of your policies with FiftyUp. ' +
    'Please visit the <a href="https://www.fiftyup.ca/" target="_blank">FiftyUp website</a> for more information on how to manage your policy.',
    contactNameChangeHelpText: 'To update the contact name on your policy please call us on <a href="tel:18443399661">1-844-339-9661</a>, between 8am and 8pm (ET) Monday to Friday.',
    contactUpdateFailureMessage: 'We were unable to update your contact details. Please try again or call us on <a href="tel:18443399661">1-844-339-9661</a>, between 8am and 8pm (ET) Monday to Friday.',
    paymentUpdateFailureMessage: 'We were unable to update your payment details. Please try again or call us on <a href="tel:18443399661">1-844-339-9661</a>, between 8am and 8pm (ET) Monday to Friday.',
    makePaymentFinaliseMessage: 'If you have any questions regarding your policy please call us on <a href="tel:18443399661">1-844-339-9661</a>, Monday to Friday between 8am and 8pm (ET), and we\'ll be happy to help.',
    makePaymentFailureMessage: 'Please try again or contact our support team on 1-844-339-9661, Monday to Friday between 8am and 8pm (ET), and we\'ll be happy to help.',
    headerText: 'Final Expenses Insurance',
    brandLogoAlt: 'FiftyUp',
    renewalLabel: 'Policy Anniversary Date',
    footerLinkLabels: ['Privacy Policy', 'Terms & Conditions', 'Make a claim', 'Contact us'],
    footerLinkDestinations: [
        'https://www.fiftyup.ca/privacy-policy',
        'https://www.fiftyup.ca/terms-and-conditions',
        'https://www.fiftyup.ca/final-expenses-insurance/claims',
        'https://www.fiftyup.ca/contact'
    ],
    footerTextHtml: '<p>FiftyUp insurance products are underwritten by Teachers Life Insurance Society (Fraternal), distributed by GFSC Life Inc. ' +
    '(Suite 1600, 2 Sheppard Avenue E, North York, ON, M2N 5Y7). Product information is for descriptive purposes only and does not provide a complete ' +
    'summary of coverage. Please refer to the relevant Policy Document to ensure these products meet your needs. Individual circumstances may vary. You ' +
    'may wish to speak with a licensed insurance agent for advice about your insurance needs. FiftyUp insurance products are not currently ' +
    'available in the province of Quebec. FiftyUp is a trade name registered by GFSC Life Inc.</p>',
    beneficiaryLink: '<a class="edit-link" href="https://www.fiftyup.ca/documents/fiftyup-beneficiary-form.pdf" target="_blank">Beneficiary Form</a>'
}

export const FiftyUpBrandPhone = '1-844-339-9661';
