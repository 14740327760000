// Externals
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Utility
import { formatMoney, formatDate } from '../../util/formats';
import { getProductTypeIcon } from '../../util/products';
import { renewalLabelSelector } from '../../store/selectors/brand';
// Styles
import './Summary.scss';

// Policy Summary component
const Summary = (props) => {
	let iconClass = getProductTypeIcon(props.ProductType.ProductTypeId);
	const policyDetail = useSelector(state => state.policy.policyDetails[props.PolicyNumber]);
	const renewalLabel = useSelector(renewalLabelSelector);
	return (
		<div className='policy-summary'>
			<div className='header'>
				<div className='policy-title'>
					<span className={iconClass}></span>
					{policyDetail && <Link to={'/policy/' + props.PolicyNumber } ><h2>{props.ProductName}</h2></Link> }
					{!policyDetail && <h2>{props.ProductName}</h2> }
				</div>
				{policyDetail && 
					<Link className='btn-link' to={'/policy/'+props.PolicyNumber}><button className='btn btn-primary btn-rounded secondary view-policy'>View policy</button></Link>
				}
			</div>
			<table>
				<tbody>
					<tr><td>Product Name</td><td>{props.ProductName}</td></tr>
					<tr><td>Policy Number</td><td>{props.PolicyNumber}</td></tr>
					<tr><td>Policy Status</td><td>{props.PolicyStatus}</td></tr>
					{props.PolicyStatus !== 'Lapsed' && props.PolicyStatus !== 'Cancelled' && <>
						<tr><td>Coverage Amount</td><td>{formatMoney(props.CoverAmount)}</td></tr>
						{props.RenewalDate &&
						<tr><td>{renewalLabel}</td><td>{formatDate(props.RenewalDate)}</td></tr>
						}
						</>
					}
					
				</tbody>
			</table>
			<div className='mobile-actionbar'>
				{policyDetail && 
					<Link className='btn-link' to={'/policy/'+props.PolicyNumber}><button className='btn btn-primary btn-rounded secondary view-policy'>View policy</button></Link> 
				}
			</div>
		</div>
	);
};

// Exports
export default Summary;
