// Externals
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiGetPolicyDetails, apiGetPolicyOutstandingPayments } from '../async/policy';

// Add policy summary (from contact API)
export const addPolicySummaryFromContact = createAction('policy/ADD_POLICY_SUMMARY_FROM_CONTACT');

// Get policy details
export const getPolicyDetail = createAsyncThunk('policy/GET_POLICY_DETAIL', 
	// TODO: abortController
	async (policyNumber, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return apiGetPolicyDetails(policyNumber, customerLoginId, token, rejectWithValue);
	}
);

//Set the number of policies expected to load
export const setPolicyCount = createAction('policy/SET_POLICY_COUNT');

// Get outstanding payments for policy
export const getPolicyOutstandingPayments = createAsyncThunk('policy/GET_PAYMENTS_OUTSTANDING', 
	async (policyNumber, { getState, dispatch, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			clientPolicyNumber: policyNumber
		}
		return await apiGetPolicyOutstandingPayments(params, token, dispatch, rejectWithValue);
	}
);

// Set the policy status to "Processing Payment"
export const setPolicyStatusPaymentPending = createAction('policy/SET_POLICY_STATUS_PENDING');

// Update the policy status
export const updatePolicyStatus = createAsyncThunk('policy/UPDATE_POLICY_STATUS', 
	async (policyNumber, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return apiGetPolicyDetails(policyNumber, customerLoginId, token, rejectWithValue);
	}
);

// Clear the policy data on logout
export const clearPolicies = createAction('policy/CLEAR_POLICIES');
