
// Externals
import { createSlice } from '@reduxjs/toolkit';

// Actions 
import { 
	clearUpdatePaymentInfo, 
	getPaymentDetailForm, 
	updatePaymentDetails,
	addSelectedPayNowTransaction,
	removeSelectedPayNowTransaction,
	clearSelectedPayNowTransactions,
	clearPayNowInfo,
	resetPayNowControl,
	getPayNowPaymentControl,
	finalisePayNowTransaction,
	markPolicyPayNowPending,
	clearPolicyPayNowPending } from '../actions/payment';

// Initial State
const initialState = {
	formPayload: {
		ClientID: 0,
		QuoteID: 0,
		ClientValid: false,
		EvolveUpdatePaymentDetailsToken: null,
		PaymentGatewayAuthToken: {},
		PaymentGatewayAPIURL: null,
		PaymentGatewayAPIEnvironment: null,
		GfsWebComponentsUrl:null
	},
	payNowGateway: {
		quoteID: null,
		apiUrl: null,
		componentUrl: null,
		authToken: null,
		clientIPAddr: null,
		ReceiptReference: null
	},
	payNowSelectedTransactions: {},
	payNowPoliciesPendingPayment: [],
	payNowError: false,
	updateSuccess: null,
	updateMessage: '',
	loading: false,
	errorMessage: false
};

// Payments Reducer
const payment = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPaymentDetailForm.pending, (state, action) => {
			state.formPayload = initialState.formPayload;
			state.loading = true;
		});

		builder.addCase(getPaymentDetailForm.rejected, (state, action) => {
			state.loading = false;
			state.updateSuccess = false;
			state.formPayload = initialState.formPayload;
		});

		builder.addCase(getPaymentDetailForm.fulfilled, (state, action) => {			
			state.formPayload = action.payload;
			state.formPayload.PaymentGatewayAuthToken = JSON.parse(state.formPayload.PaymentGatewayAuthToken);
			state.loading = false;
		});

		builder.addCase(updatePaymentDetails.pending, (state, action) => {
			state.loading = true;
			state.updateSuccess = null;
			state.updateMessage = null;
		});

		builder.addCase(updatePaymentDetails.rejected, (state, action) => {
			state.loading = false;
			state.updateSuccess = false;
			state.formPayload = initialState.formPayload;
		});

		builder.addCase(updatePaymentDetails.fulfilled, (state, action)=>{
			state.loading = false;
			state.updateSuccess = action.payload.IsSuccess;
			state.updateMessage = action.payload.SuccessMessage;
		});

		// Update the list of selected payments for Pay Now
		builder.addCase(addSelectedPayNowTransaction, (state, action) => {
			const { transactionId, amount } = action.payload;
			if (!state.payNowSelectedTransactions[transactionId]) 
				state.payNowSelectedTransactions[transactionId] = amount;
		});
		builder.addCase(removeSelectedPayNowTransaction, (state, action) => {
			const { transactionId } = action.payload;
			if (state.payNowSelectedTransactions[transactionId]) 
				delete state.payNowSelectedTransactions[transactionId];
		});

		// Get the payment control for Pay Now
		builder.addCase(getPayNowPaymentControl.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getPayNowPaymentControl.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(getPayNowPaymentControl.fulfilled, (state, action) => {
			state.payNowGateway.quoteID = action.payload.QuoteID;
			state.payNowGateway.apiUrl = action.payload.PaymentGatewayAPIURL.replace(/\/api$/, '');
			state.payNowGateway.componentUrl = action.payload.GfsWebComponentsUrl.replace(/sit2/, 'sit');
			state.payNowGateway.authToken = JSON.parse(action.payload.PaymentGatewayAuthToken).access_token;
			state.payNowGateway.clientIPAddr = action.payload.ClientIpAddress;
			state.payNowGateway.ReceiptReference = initialState.payNowGateway.ReceiptReference;
			state.loading = false;
		});

		// Finalise the Pay Now transaction
		builder.addCase(finalisePayNowTransaction.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(finalisePayNowTransaction.rejected, (state, action) => {
			state.loading = false;
			state.payNowError = true;
		});
		builder.addCase(finalisePayNowTransaction.fulfilled, (state, action) => {
			state.payNowGateway.ReceiptReference = action.payload.ReceiptReference;
			state.payNowSelectedTransactions = initialState.payNowSelectedTransactions;
			state.loading = false;
		});

		// Mark policy has pending payment (result of successful Pay Now workflow)
		builder.addCase(markPolicyPayNowPending, (state, action) => {
			const policyNumber = String(action.payload);
			if (!state.payNowPoliciesPendingPayment.includes(policyNumber))
				state.payNowPoliciesPendingPayment.push(policyNumber);
		});

		// Clear policy's pending payment
		builder.addCase(clearPolicyPayNowPending, (state, action) => {
			const policyNumber = String(action.payload);
			if (state.payNowPoliciesPendingPayment.includes(policyNumber))
				state.payNowPoliciesPendingPayment = state.payNowPoliciesPendingPayment.filter(pNumber => pNumber !== policyNumber)
		});

		// Clear any payment details (after success/logout)
		builder.addCase(clearUpdatePaymentInfo, (state, action) => {
			state.updateSuccess = null;
			state.updateMessage = null;
		});

		// Clear any payment details (after success/logout)
		builder.addCase(clearPayNowInfo, (state, action) => {
			state.payNowSelectedTransactions = initialState.payNowSelectedTransactions;
			state.payNowPoliciesPendingPayment = initialState.payNowPoliciesPendingPayment;
			state.payNowGateway = initialState.payNowGateway;
		});

		// Reset the pay now gateway
		builder.addCase(resetPayNowControl, (state, action) => {
			state.payNowGateway = initialState.payNowGateway;
		});

		// Clear any payment details (after success/logout)
		builder.addCase(clearSelectedPayNowTransactions, (state, action) => {
			state.payNowSelectedTransactions = initialState.payNowSelectedTransactions;
		});

	}
});

// Exports
export default payment.reducer;
