// Utility
import { sortDateDescending, sortDateAscending, sortString } from '../../util/sort';

// return policy documents object as array
export const policyDocumentsSelector = (state, policyNumber, dateSort='desc', eventSort=null) => {
	if(state.documents.policyDocuments[policyNumber]){
		//Collect data to group events by
		const uniqueEntries = [];
		const policy = state.policy.policyDetails[policyNumber];
		const documents = state.documents.policyDocuments[policyNumber].filter(x => x.FolderFields.FileType.toLowerCase() === 'pdf').slice();		
		documents.forEach(x => {
			const eventType = x.FolderFields.CorrespondenceType ? x.FolderFields.CorrespondenceType.toLowerCase() : x.FolderFields.DocumentType && x.FolderFields.DocumentType === 'PD' ? "welcome" : "";
			const sortData = { date: x.FolderFields.CorrespondenceDate ? x.FolderFields.CorrespondenceDate.split('T')[0] : policy.PolicySummary.AcceptanceDate.split('T')[0], event: eventType };
			const index = uniqueEntries.find(x => x.date === sortData.date && x.event === sortData.event);
			if(index === undefined) {
				uniqueEntries.push(sortData);
			}
		});		

		//Group document data 
		// slice() takes a writeable copy of the redux state
		const retval = [];
		uniqueEntries.forEach(x => {
			retval.push(documents.filter(y => 
				{
					if(y.FolderFields.CorrespondenceDate){
						return y.FolderFields.CorrespondenceDate.split('T')[0] === x.date && y.FolderFields.CorrespondenceType.toLowerCase() === x.event
					}
					
					if(!y.FolderFields.CorrespondenceDate && y.FolderFields.DocumentType && y.FolderFields.DocumentType === "PD") {
						return policy.PolicySummary.AcceptanceDate.split('T')[0] === x.date && x.event === "welcome" 
					}
				}).map(x => { 
					return {
						...x, 
						...(x.FolderFields.DocumentType && x.FolderFields.DocumentType === "PD" && {FolderFields: {CorrespondenceType: "Welcome", FileName: x.FolderFields.FileName}})
					}
				}).sort((a, b) => {
					return !dateSort || dateSort === 'desc' ? sortDateDescending(a, b) : sortDateAscending(a, b);
				})
			);
		});		
		//Sort the grouped data
		retval.sort((a, b) => {
			if(!a || !b || a.length === 0 || b.length === 0)
				return 0;

			if(dateSort !== null){				
				var dateA = a[0].FolderFields.CorrespondenceDate ? a[0].FolderFields.CorrespondenceDate.split('T')[0] : policy.PolicySummary.AcceptanceDate.split('T')[0];
				var dateB = b[0].FolderFields.CorrespondenceDate ? b[0].FolderFields.CorrespondenceDate.split('T')[0] : policy.PolicySummary.AcceptanceDate.split('T')[0];
				if (dateSort === 'desc') return sortDateDescending(dateA, dateB);
				else return sortDateAscending(dateA, dateB);
			}

			if(eventSort !== null) {
				return sortString(a[0].FolderFields.CorrespondenceType.toLowerCase(), b[0].FolderFields.CorrespondenceType.toLowerCase(), eventSort !== 'desc');
			}
		});

		return retval;
	}
};

// return the document type (pdf or eml)
export const documentTypeSelector = (state, policyNumber, documentNumber) => {
	const document = state.documents.policyDocuments[policyNumber] && state.documents.policyDocuments[policyNumber].find(
		document => document.DocId === documentNumber.split(" ").join("+")
	);

	return (document && document.FolderFields.FileType.toLowerCase()) || null;
};


export const documentListCookieSelector = (state, policyNumber, docId) => {
	const document = state.documents.policyDocuments[policyNumber] && state.documents.policyDocuments[policyNumber].find(
		document => document.DocId === docId
	);
	return document && document.QuadientHeaders ? document.QuadientHeaders : null;
}

export const allDocumentsLoadedSelector = (state) => state.policy.policyCount !== null && state.documents.loadedDocumentLists >= state.policy.policyCount;