// Externals
import { createSlice } from '@reduxjs/toolkit';

// Actions 
import { 
	getPolicyDocumentList,
	clearPolicyDocuments } from '../actions/documents';

// Initial State
const initialState = {
	loading: false,
	policyDocuments: {},
	loadedDocumentLists: 0
};

// Policy Reducer
const policy = createSlice({
	name: 'documents',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
	
		// Load the policy documentlist
		builder.addCase(getPolicyDocumentList.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getPolicyDocumentList.rejected, (state, action) => {
			state.loading = false;
			state.loadedDocumentLists = state.loadedDocumentLists + 1;
		});
		builder.addCase(getPolicyDocumentList.fulfilled, (state, action) => {
			state.loading = false;			
			state.policyDocuments[action.meta.arg.PolicyNumber] = action.payload.HitList;
			state.loadedDocumentLists = state.loadedDocumentLists + 1;
		});

		// Clear the policy documents (for Logout)
		builder.addCase(clearPolicyDocuments, (state, action) => {
			Object.assign(state, initialState);
		});

	}
});

// Exports
export default policy.reducer;
