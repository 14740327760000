// Strings
import { FiftyUpBrandHeadings, FiftyUpBrandMessages, FiftyUpBrandPhone } from './FiftyUp/strings/BrandMessages';
import { NorthCoverBrandHeadings, NorthCoverBrandMessages, NorthCoverBrandPhone } from './NorthCover/strings/BrandMessages';

// Logos
import FiftyUpLogo from './FiftyUp/static/FiftyUpLogo.svg';
import NorthCoverLogo from './NorthCover/static/NorthCoverLogo.svg';

const setBrandHeadings = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandHeadings;
		case 'northcover':
			return NorthCoverBrandHeadings;
        default:
            return FiftyUpBrandHeadings;
    }
}

const setBrandMessages = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandMessages;
		case 'northcover':
			return NorthCoverBrandMessages;
        default:
            return FiftyUpBrandMessages;
    }
}

const setBrandPhone = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandPhone;
		case 'northcover':
			return NorthCoverBrandPhone;
        default: 
            return FiftyUpBrandPhone;
    }
}

const setBrandLogo = (brand) => {
    switch (brand) {
        case 'fiftyup':
            return FiftyUpLogo;
		case 'northcover':
			return NorthCoverLogo;
        default:
            return FiftyUpLogo;
    }
}

const createBrandState = (brand) => {
    return {
		brandHeadings: setBrandHeadings(brand),
        brandMessages: setBrandMessages(brand),
        brandPhone: setBrandPhone(brand),
        brandLogo: setBrandLogo(brand)
    }
}

const customisations = {
    fiftyup: createBrandState('fiftyup'),
	northcover: createBrandState('northcover')
}

export default customisations;
