// Externals
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { validPoliciesSelector, policyDetailIsLoadingSelector, allPoliciesLoaded, allPoliciesLoadedSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';
// Components
import Container from '../components/Layout/Container';
import PolicyHeader from '../components/Widgets/PolicyHeader';
import UpdatePaymentForm from '../components/Forms/UpdatePaymentForm';
import SpinnerOverlay from '../components/Widgets/SpinnerOverlay';

// Pages
import Error404 from '../pages/Error404';

// Styles
import './UpdatePayment.scss';


// Payment container
const Payment = () => {

	const { policyNumber } = useParams();
	const validPolicies = useSelector(validPoliciesSelector);
	const policyDetailLoading = useSelector(policyDetailIsLoadingSelector);
	const allPoliciesLoaded = useSelector(allPoliciesLoadedSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myPaymentDetails'));
	const pageSubHeading = useSelector(state => headingSelector(state, 'editPaymentDetails'));
	
	if (allPoliciesLoaded && !validPolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	else return (
		<>
			{allPoliciesLoaded && <PolicyHeader /> }
			<Container>
				<div className='page-update-payment'>
					{!allPoliciesLoaded &&
						<SpinnerOverlay message="Loading your policy details..." />
					}

					{allPoliciesLoaded && <>
						<h1>{pageHeading}</h1>
						<h2>{pageSubHeading}</h2>
						
						<UpdatePaymentForm /></>
					}
					
						
				</div>
			</Container>
		</>
	);
};

// Exports
export default Payment;
