// Externals
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

// API methods
import { apiGetPaymentAuth, 
	apiUpdatePaymentDetails, 
	apiGetPayNowPaymentControl,
	apiFinalisePayNowTransaction } from '../async/payment';

// Get authorization for updating payments details
export const getPaymentDetailForm = createAsyncThunk('payment/GET_PAYMENT_AUTH', 
	async (policyDetails, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			...policyDetails
		}
		return await apiGetPaymentAuth(params, token, rejectWithValue);
	}
);

// Process update customer payment details
export const updatePaymentDetails = createAsyncThunk('payment/UPDATE_PAYMENT_DETAILS', 
	async (queryParams, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			ClientIPAddress: '127.0.0.1',
			QueryStrings: [...queryParams].reduce((o, i) => ({ ...o, [i[0]]: i[1] }), {})
		}
		return await apiUpdatePaymentDetails(params, token, rejectWithValue);
	}
);

// Get authorization for Pay Now 
export const getPayNowPaymentControl = createAsyncThunk('payment/GET_PAY_NOW_CONTROL', 
	async (policyNumber, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			clientPolicyNumber: policyNumber
		}
		return await apiGetPayNowPaymentControl(params, token, rejectWithValue);
	}
);

// Finalise Pay Now transaction
export const finalisePayNowTransaction = createAsyncThunk('payment/FINALISE_PAY_NOW_TRANSACTION', 
	async (args, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			ClientPolicyNumber: args.policyNumber,
			ClientIPAddress: getState().payment.payNowGateway.clientIPAddr,
			TransactionIdentifier: args.transactionIdentifier,
			Payments: args.transactionIDs.map(transactionId => (
				{ TransactionId: transactionId }
			))
		};
		return await apiFinalisePayNowTransaction(params, token, rejectWithValue);
	}
);

// Mark policy transactions as pending (via Pay Now)
export const markPolicyPayNowPending = createAction('payment/MARK_POLICY_PAY_NOW_PENDING');
export const clearPolicyPayNowPending = createAction('payment/CLEAR_POLICY_PAY_NOW_PENDING');

// Selected payments for Pay Now 
export const addSelectedPayNowTransaction = createAction('payment/ADD_PAY_NOW_TRANSACTION');
export const removeSelectedPayNowTransaction = createAction('payment/REMOVE_PAY_NOW_TRANSACTION');

// Clear the payment state after successful payment / logout
export const clearUpdatePaymentInfo = createAction('payment/CLEAR_UPDATE_PAYMENT');

// Clear the pay now data on session timeout / logout
export const clearPayNowInfo = createAction('payment/CLEAR_PAY_NOW');

// Reset the pay now gateway state
export const resetPayNowControl = createAction('payment/RESET_PAY_NOW');

// Clear the selected transactions state
export const clearSelectedPayNowTransactions = createAction('payment/CLEAR_SELECTED_PAY_NOW_TRANSACTIONS');
