// Map .env variables

// NOTE: Edit in the related .env file; do not edit values here.

const config = {

	API_HOST: process.env.REACT_APP_API_HOST,

	// e.g. real, seniors
	BRAND: process.env.REACT_APP_BRAND,

	// integer
	BRAND_ID: parseInt(process.env.REACT_APP_BRAND_ID, 10),

	// integer
	DEFAULT_COUNTRY_ID: parseInt(process.env.REACT_APP_DEFAULT_COUNTRY_ID, 10),

	// Google Tag Manager ID
	GTM_ID: process.env.REACT_APP_GTM_ID,
	
	// local, uat, production
    ENVIRONMENT: process.env.REACT_APP_ENV,

	// true or false; disables redux devtools
	DEBUG: process.env.REACT_APP_DEBUG === 'true' ? true : false,

	// true or false; enables/disables Documents feature
	DOCUMENTS_FEATURE_FLAG: process.env.REACT_APP_DOCUMENTS_FEATURE_FLAG === 'true' ? true : false

};

// Export
export default config;
