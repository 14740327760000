// Externals
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addSelectedPayNowTransaction, removeSelectedPayNowTransaction } from '../../store/actions/payment';

// Selectors
import { allOutstandingPaymentsLoadedSelector, overduePolicyPaymentsSelector } from '../../store/selectors/policy';
import { selectedPayNowTransactionIDsSelector, selectedPayNowTransactionIDsTotalAmountSelector } from '../../store/selectors/payment';

// Utility
import { formatMoneyWithDecimal } from '../../util/formats';

// Styles
import './SelectPayments.scss';

// Constant
export const creditCard = 0;

// Widget to select outstanding payments, ready for processing
const SelectPayments = ({ handlePayment, handleCancel }) => {
	const dispatch = useDispatch();
	const { policyNumber } = useParams();
	const overduePayments = useSelector(state => overduePolicyPaymentsSelector(state, policyNumber));
	const selectedTransactionIDs = useSelector(selectedPayNowTransactionIDsSelector);
	const totalAmount = useSelector(selectedPayNowTransactionIDsTotalAmountSelector);
	const allOutstandingPaymentsLoaded = useSelector(allOutstandingPaymentsLoadedSelector);

	const handleCheckbox = (e) => {
		const transaction = overduePayments.filter(item => item.transactionId === Number(e.target.value))[0];
		delete(transaction.dueDate); // harmless
		if (e.target.checked)
			dispatch(addSelectedPayNowTransaction(transaction));
		else
			dispatch(removeSelectedPayNowTransaction(transaction));
	}

	const checkDisabled = (index) => {
		if (index === 0) return false;
		else if (selectedTransactionIDs.includes(String(overduePayments[index-1].transactionId))) { 
			return false; 
		}
		else {
			dispatch(removeSelectedPayNowTransaction((overduePayments[index])));
			return true;
		}
	}

	useEffect(() => {
		window.scrollTo({ top: 0, behaviour: 'smooth'});	
	}, []);

	return (
		<div className='form form-select-payments'>

			{allOutstandingPaymentsLoaded && overduePayments !== null && overduePayments.length > 0 && (
				<div className='select-payments-table'>
					{overduePayments.map((payment, index) => {
						const disabled = checkDisabled(index);
						return (
							<div key={index} className={'select-payments-row' + (disabled ? ' disabled' : '')}>
								<span key={payment.transactionId}>{payment.dueDate}</span>
								<span className='amount'>
									{formatMoneyWithDecimal(payment.amount)}
									<input 
										type='checkbox' name='transactionId' 
										value={payment.transactionId}
										checked={selectedTransactionIDs.includes(String(payment.transactionId))}
										onChange={handleCheckbox}
										disabled={disabled} />
								</span>
							</div>
						);
					})}
				</div>
			)}

			<div className='total-outstanding'>
				<span>Total Outstanding</span>
				<span className='total-amount'>{formatMoneyWithDecimal(totalAmount)}</span>
			</div>

			<div className='action-buttons'>
				<button className='btn form-btn btn-rounded submit cancel secondary' onClick={handleCancel}>Cancel</button>
				<button className='btn form-btn btn-rounded submit' onClick={handlePayment} disabled={selectedTransactionIDs.length === 0}>Continue to payment &gt;</button>
			</div>

		</div>
	);
};

export default SelectPayments;
